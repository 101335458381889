import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IbanGeneratorService {

  transformerObj: { [key: string]: any } = {
    A: 10,
    B: 11,
    C: 12,
    D: 13,
    E: 14,
    F: 15,
    G: 16,
    H: 17,
    I: 18,
    J: 19,
    K: 20,
    L: 21,
    M: 22,
    N: 23,
    O: 24,
    P: 25,
    Q: 26,
    R: 27,
    S: 28,
    T: 29,
    U: 30,
    V: 31,
    W: 32,
    X: 33,
    Y: 34,
    Z: 35,
  }

  constructor() { }
/**
 * generates a algorithmic correct iban
 * @returns IBAN
 */
  generateIban(): string {
    let countryCode = "DE";
    let blz = "80218500"
    let accountNumber: string = "";
    let iban = "";
    let ccNumberStr = "";
    for (let i = 0; i < 10; i++) {
      accountNumber += Math.floor(Math.random() * 10);
    }
    //decode countryCode
    Array.from(blz).forEach(character => {
      ccNumberStr += character;
    })
    ccNumberStr += accountNumber.toString();
    //transform countrycode to numberString
    Array.from(countryCode).forEach(letter => {
      ccNumberStr += this.transformerObj[letter]
    });
    // add two placeholders to numberstring for checkcode
    ccNumberStr += "00"
    // convert ccNumberString to bigInt
    let ccNumber = BigInt(ccNumberStr);
    // compute checknumber
    let checkCode = BigInt(98) - (ccNumber % BigInt("97"));
    checkCode.toString().length === 1 ?
      iban = countryCode + "0" + checkCode.toString() + blz + accountNumber :
      iban = countryCode + checkCode.toString() + blz + accountNumber;
    return iban
  }
/**
 * 
 * @param iban IBAN to validate
 * @returns if IBAN is valid
 */
  validateIban(iban: string): boolean {
    let isValid = false;
    let countryCode = iban.slice(0, 4);
    let bban = iban.slice(4, iban.length);
    let convertedCountryCode: string = "";
    for (let i = 0; i < countryCode.length; i++) {
      if (typeof countryCode[i] !== 'number') {
        if (this.transformerObj.hasOwnProperty(countryCode[i].toUpperCase())) {
          convertedCountryCode += this.transformerObj[countryCode[i].toUpperCase().toString()]
        } else {
          convertedCountryCode += countryCode[i];
        }
      }
    }
    let bbanString = "";
    Array.from(bban).forEach(character => {
      if (Number.isNaN(parseInt(character))) {
        bbanString += this.transformerObj[character];
      } else {
        bbanString += character
      }
    })
    let evalString = bbanString + convertedCountryCode;
    if ((BigInt(evalString) % BigInt(97)) === BigInt(1)) {
      isValid = true
    }
    return isValid;
  }
}
