import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { MessageType } from '../../ui/dialog-handler/dialog-handler.component';

@Component({
  selector: 'app-openid4vp-login',
  templateUrl: './openid4vp-login.component.html',
  styleUrls: ['./openid4vp-login.component.scss']
})
export class Openid4vpLoginComponent implements OnInit {

  showLoader: boolean = false;
  appUrl: string = "";
  showQrCode = false;
  alertMessage: string = "";
  hintMessage: string = "";
  sessionId: string = "";
  oobid: string = "";
  credentials: any = [];
  handle: any = null;
  readonly timeout: number = 2*60*1000;
  blnShowDialogHandler: boolean = false;
  messageType: MessageType = MessageType.BackDialog;

  credentialPropertyEnums: any = {
    "given_name": "Vorname",
    "family_name": "Nachname"
  };

  constructor(private dataService: DataService,
    private router: Router) {
  }

  ngOnInit() {
    this.showLoader = true;
    this.getQRCodeData();
  }

  ngOnDestroy() {
    clearInterval(this.handle);
  }

  /**
   * to get the data for qr-code
   */
  getQRCodeData() {
    this.dataService.get(environment['DC_URL'] + '/login_vp?type=eudi_PID&subtype=').then(response => {
      this.showLoader = false;
      if (response !== null
        && response.url !== undefined && response.url !== ""
        && response.sid !== undefined && response.sid !== ""
      ) {
        this.appUrl = response.url;
        this.sessionId = response.sid;
        this.showQrCode = true;
        const startTime = new Date().getTime();
        this.handle = setInterval(() => {
          this.getResultData().then((result: any) => {
            this.credentials = [];
            if (result !== null && result !== undefined && result !== "" && result !== 401 && result !== 400 && result !== 500) {
              clearInterval(this.handle);
              this.credentials = this.processCredentialData(result);
            } else if (new Date().getTime() - startTime > this.timeout) {
              this.alertMessage = "Wartezeitraum abgelaufen. Der Server antwortet nicht."
              this.hintMessage = "Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut."
              this.blnShowDialogHandler = true;
              clearInterval(this.handle);
            } else if (result === 400 || result === 500) {
              this.alertMessage = "Es ist ein Fehler aufgetreten: Statuscode " + result;
              switch (result) {
                case 400: this.hintMessage = "Request fehlerhaft.";
                  break;
                case 500: this.hintMessage = "Fehler auf Serverseite."
                  break;
              }
              this.blnShowDialogHandler = true;
            }
          });
        }, 5000);
      } else if ((response === null || response === "") || (response.status !== undefined && response.status !== 200)) {
        this.alertMessage = response.statusText;
        this.blnShowDialogHandler = true;
      }
    });
  }

  /**
   * to get results
   * @returns list of 
   */
  getResultData(): Promise<any> {
    return new Promise<Object>((resolve, reject) => {
      this.dataService.get(environment['DC_URL'] + '/api/result').then(response => {
        resolve(response);
        return response;
      });
    });
  }

  /**
   *  to process the received credentials data
   * @param data 
   * @returns processed array of key-value pair of properties in credentials
   */
  processCredentialData(data: any) {
    let credentials: any = [];
    if (Array.isArray(data) && data.length > 0) {
      data.forEach(cred => {
        if (cred["credentialSubject"] !== undefined) {
          let keys = Object.keys(cred["credentialSubject"]);
          if (Array.isArray(keys) && keys.length > 0) {
            let credentialProps: any = [];
            keys.forEach(key => {
              credentialProps.push({ "key": key, "value": cred["credentialSubject"][key] });
            });
            credentials.push(credentialProps);
          }
        }
      });
    }
    return credentials;
  }

  /**
* catches emitter from dialog-handler
*/
  cancelAction() {
    this.blnShowDialogHandler = false;
  }

  /**
 * catches emitter from dialog-handler
 */
  okAction() {
    this.blnShowDialogHandler = false;
  }
}