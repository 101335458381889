import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private config: AppConfig = {
    TIMESTAMP: ""
  };
  loaded = false;
  constructor() { }
  loadConfig(): Promise<void> {
    return fetch(window.origin + '/config/config.json').then(response => {
      if (response.body instanceof ReadableStream) {
        return (response.json())
      } else {
        return response;
      }
    }).then(data => {
      if (this.isInstanceOfAppConfig(data)) {
        this.config.TIMESTAMP = data.TIMESTAMP;
        this.loaded = true;
      }
    });
  }

  getConfig(): AppConfig {
    return this.config;
  }

  isInstanceOfAppConfig(object: any): object is AppConfig {
    let timestamp = 'TIMESTAMP' in object;
    if (timestamp) {
      return true
    } else {
      return false;
    }
  }
}

export interface AppConfig {
  TIMESTAMP: string;
}