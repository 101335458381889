import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import * as de from '@angular/common/locales/de';
import { BrowserModule } from '@angular/platform-browser';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { GetLangPipe } from './pipe/get-lang.pipe';

import { AppComponent } from './app.component';
import { TicketshopComponent } from './components/credentials/ticketshop/ticketshop.component';
import { TicketBuyComponent } from './components/credentials/ticketbuy/ticketbuy.component';
import { FooterComponent } from './components/ui/footer/footer.component';
import { ShowQrCodeComponent } from './components/ui/showqrcode/showqrcode.component';
import { CredentialComponent } from './components/credentials/credential/credential.component';
import { ProgressBarComponent } from './components/ui/progress-bar/progress-bar.component';
import { HttpClientTestingModule } from '@angular/common/http/testing';
import { Base64ImageDecodePipe } from './pipe/base64-image-decode.pipe';
import { HasTypePipe } from './pipe/has-type.pipe';
import { WebsocketService } from './services/websocket.service';
import { CreateMessageService } from 'src/app/services/create-message.service';
import { SchemaService } from 'src/app/services/schema.service';
import { DataService } from './services/data.service';
import { HomeComponent } from './components/ui/home/home.component';
import { WohngeldbescheidComponent } from './components/credentials/wohngeldbescheid/wohngeldbescheid.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PartySelectorComponent } from './components/ui/party-selector/party-selector.component';
import { DefaultCredentialComponent } from './components/credentials/default-credential/default-credential.component';
import { PrivacyPoliceComponent } from './components/ui/privacy-police/privacy-police.component';
import { TermsConditionsComponent } from './components/ui/terms-conditions/terms-conditions.component';
import { DresdenPassComponent } from './components/credentials/dresdenpass/dresden-pass.component';
import { SccVerificationComponent } from './components/credentials/scc-verification/scc-verification.component';
import { SepaMandatePresentationComponent } from './components/credentials/sepa-mandate-presentation/sepa-mandate-presentation.component';
import { PresentationComponent } from './components/ui/presentation/presentation.component';
import { ToasterComponent } from './components/ui/toaster/toaster.component';
import { BadaComponent } from './components/credentials/bada/bada.component';
import { BadaLoginComponent } from './components/credentials/bada-login/bada-login.component';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { KommpassComponent } from './components/credentials/kommpass/kommpass.component';
import { WaiterComponent } from './components/ui/waiter/waiter.component';
import { AppConfigService } from 'src/app-config.service';
import { MobileContractComponent } from './components/credentials/mobile-contract/mobile-contract.component';
import { NextbikeComponent } from './components/credentials/nextbike/nextbike.component';
import { DrivingLicenseComponent } from './components/credentials/driving-license/driving-license.component';
import { TeilautoComponent } from './components/credentials/teilauto/teilauto.component';
import { JungheinrichComponent } from './components/credentials/jungheinrich/jungheinrich.component';
import { MobileContractLoginComponent } from './components/credentials/mobile-contract-login/mobile-contract-login.component';
import { Openid4vpLoginComponent } from './components/credentials/openid4vp-login/openid4vp-login.component';
import { DialogHandlerComponent } from './components/ui/dialog-handler/dialog-handler.component';

@NgModule({
  declarations: [
    AppComponent,
    TicketshopComponent,
    TicketBuyComponent,
    FooterComponent,
    ShowQrCodeComponent,
    CredentialComponent,
    ProgressBarComponent,
    GetLangPipe,
    Base64ImageDecodePipe,
    HasTypePipe,
    HomeComponent,
    WohngeldbescheidComponent,
    PartySelectorComponent,
    DefaultCredentialComponent,
    PrivacyPoliceComponent,
    TermsConditionsComponent,
    DresdenPassComponent,
    SccVerificationComponent,
    SepaMandatePresentationComponent,
    PresentationComponent,
    ToasterComponent,
    BadaComponent,
    BadaLoginComponent,
    KommpassComponent,
    WaiterComponent,
    MobileContractComponent,
    NextbikeComponent,
    DrivingLicenseComponent,
    TeilautoComponent,
    JungheinrichComponent,
    MobileContractLoginComponent,
    Openid4vpLoginComponent,
    DialogHandlerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientTestingModule,
    NgbModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService],
      multi: true,},
      AppConfigService,
    provideAnimations(),
    DataService,
    WebsocketService,
    CreateMessageService,
    SchemaService,
    GetLangPipe
  ],
  exports: [
    GetLangPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(de.default); //Then register the language
  }
}

export function initConfig(appConfig: AppConfigService) {
  return () => appConfig.loadConfig();
}
