<header class="heading">Wohngeldbescheid</header>

<div class="main-wrapper container-fluid">
  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div class="card border-0 card_shadow">
      <div class="card-body" *ngIf="!showQRCode">
        <p>Für den Anwendungsfall Sozialpass ist ein zusätzlicher Nachweis erforderlich, der in der Regel von einem
          Sozialamt ausgestellt wird. Zur Vereinfachung der Demonstration wird hier nur ein Wohngeldbescheid ausgegeben.
        </p>
      </div>
    </div>
  </div>
  <app-dialog-handler class="dialog-handler" *ngIf="blnShowDialogHandler && messageType!==undefined" [messageType]="messageType" (CancelAction)="cancelAction()" (OkAction)="okAction()" [headingMessageText]="alertMessage" [additionalMessageText]="hintMessage"></app-dialog-handler>
  <div style="display: block;" class="vh-73">
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="showQRCode">
      <div class="card border-0 card_shadow">
        <app-showqrcode class="qr-code" *ngIf="showQRCode" [verificationUrl]="appUrl" [isRequestPresentation]="false"></app-showqrcode>
      </div>
    </div>
    <div [ngClass]="{'flex-wrapper': true}">
      <button *ngIf="!showQRCode" type="submit" (click)="onFinalSubmit()" class="link-box-white">Nachweis
        Abholen</button>
      <button (click)="onClickBack()" class="link-box-white" *ngIf="!showQRCode">Abbrechen</button>
    </div>
  </div>
</div>
  <app-toaster *ngIf="messageText.length > 0" [messageText]="messageText" [status]="statusOk"></app-toaster>