import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-handler',
  templateUrl: './dialog-handler.component.html',
  styleUrls: ['./dialog-handler.component.scss']
})
export class DialogHandlerComponent implements OnInit, AfterViewInit {
  @Input() messageType: MessageType = MessageType.BackDialog;
  @Input() headingMessageText: string = "";
  @Input() additionalMessageText: string = "";
  @Input() navigateTo: string = "home";

  @Output() CancelAction: EventEmitter<any> = new EventEmitter();
  @Output() OkAction: EventEmitter<any> = new EventEmitter();

  @ViewChild('focus', { static: false }) focus: ElementRef<HTMLDivElement> = {} as ElementRef;

  isDialogSingleButton: boolean = false;
  isVisible: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.isVisible = true;
    if (this.messageType === MessageType.BackDialog) {
      this.isDialogSingleButton = true;
    }
  }

  ngAfterViewInit() {
    if (this.focus) {
      this.focus.nativeElement.focus()
    }
  }

  // request confirmation
  onDialogCancel() {
    this.CancelAction.emit()
    this.isVisible = false;
  }

  onDialogOk() {
    this.OkAction.emit();
    this.isVisible = false;
    this.router.navigate([this.navigateTo])
  }
}

export enum MessageType {
  BackDialog,
  OkCancelDialog
}
