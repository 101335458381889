import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { CreateMessageService } from 'src/app/services/create-message.service';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CredentialBuilderService } from 'src/app/services/credential-builder.service';
import { MessageContainer } from 'src/app/models/messages/messageContainer.model';
import { VerifiableCredential1xModel } from 'src/app/models/w3c/vcdm/verifiable-credential-1x.model';
import * as qrcode from 'qrcode';
import { BesuchernachweisModel } from 'src/app/models/kaprion/vc/besuchernachweis.model';
import { Issuer } from 'src/app/models/issuer.model';

@Component({
  selector: 'app-scc-verification',
  templateUrl: './scc-verification.component.html',
  styleUrls: ['./scc-verification.component.scss']
})
export class SccVerificationComponent implements OnInit {

  content: string = "";
  buttonText: string = "Neue Abfrage";
  appUrl: string = "";

  htmlElementCanvas: HTMLElement | null = null;
  htmlElementResponseText: HTMLElement | null = null;
  htmlElementCheckBox: HTMLElement | null = null;

  isWaitingForResponse: boolean = false;

  checkDresden = false;
  checkLeipzig = false;
  checkEECC = false;
  checkHMW = false;
  checkHTWD = false;
  checkFFIT = false;
  checkKT = false;
  checkCounter = 0;

  // process related objects
  sessionId: string = "";
  credentialArray: any[] = [];
  vcReqArray: any[] = [];
  presentationID: string = "";

  constructor(
    private dataService: DataService,
    private createMessageService: CreateMessageService,
    private localDataStorageService: LocalDataStorageService,
    private router: Router, // not required now
    private csb: CredentialBuilderService) {
  }

  ngOnInit(): void {
    this.sessionId = crypto.randomUUID();
    this.localDataStorageService.setData("sessionId", this.sessionId);
    this.htmlElementCanvas = document.getElementById("canvas");
    this.htmlElementResponseText = document.getElementById("response");
    this.htmlElementCheckBox = document.getElementById("checkbox");
    this.htmlElementCheckBox!.style.display = 'block';
  }

  /**
   * Resets all values to default
   */
  reset() {
    this.checkDresden = false;
    this.checkLeipzig = false;
    this.checkEECC = false;
    this.checkHMW = false;
    this.checkHTWD = false;
    this.checkFFIT = false;
    this.checkKT = false;
    this.checkCounter = 0;
    this.credentialArray = [];
    this.vcReqArray = [];
    this.presentationID = "";
    this.sessionId = crypto.randomUUID();
    this.localDataStorageService.setData("sessionId", this.sessionId);
    this.htmlElementCheckBox!.style.display = 'block';
    this.htmlElementCanvas!.style.display = 'none';
    this.htmlElementResponseText!.style.display = 'none';
    this.appUrl = "";
    try {
      const pic = document.querySelector('#stand');
      if (pic !== null) pic.remove();
    } catch (e) { }
  }

  /**
   * Sends a present proof task to CS to request a credential from holder
   * @private
   */
  private genPPPMessage() {
    this.presentationID = crypto.randomUUID();
    this.localDataStorageService.setData("ticketIdArray", [this.presentationID]); // presentation_definition.id
    this.localDataStorageService.setData("taskType", "de.kaprion.ppp.s2p");

    this.createMessageService.prepareMessageShortenedVersion(Issuer.getIDIdealIssuer(), true, true, false).then(msg => {
      if (msg instanceof MessageContainer) {
        if (msg.getMessages().length < msg.getTaskAmount()) {
          msg.setTaskAmount(msg.getMessages().length);
        }

        msg = JSON.stringify(msg, (key, value) => {
          if (value === undefined || value === null) {
            return undefined;
          }
          // Filtering null and undefined values from arrays
          if (Array.isArray(value)) {
            return value.filter((item) => item !== null && item !== undefined);
          }
          return value;
        });

       
        this.dataService.sendExpectJSON(msg, environment['DC_URL'] + '/initSessionValidation').then(response => {
          
          let status = response['success'];
          let attach = response['attachments']['data']['json']['verifiableCredential'];

          if (!status) {
            this.htmlDomElementOfString(`<div id="stand">Es wurde keine Übereinstimmung gefunden.</div>`).forEach(it => {
              document.getElementById('response')!.appendChild(it);
            });
            this.htmlElementCanvas!.style.display = 'none';
            this.htmlElementResponseText!.style.display = 'block';
          }
          else {

            if (Array.isArray(attach)) {
              this.credentialArray = <VerifiableCredential1xModel[]>attach;
            }
            else {
              this.credentialArray.push(<VerifiableCredential1xModel>attach);
            }

            const vc: VerifiableCredential1xModel[] = [];
            this.credentialArray.forEach(credential => {
              if ((<VerifiableCredential1xModel>credential).type.includes("Besuchernachweis")) {
                vc.push(credential)
              }
            });
            this.processReceivedBesuchernachweisVC(vc);
          }
        }).catch((e) => {
          
          console.warn("Rejected: " + e);
        });
      }
    });
    this.appUrl = environment['APP_URL'] + "/VcIssuerSp/verify" +
      "?_oobid=" + this.localDataStorageService.getData("oobid") +
      "&credentialProviderLink=" + environment['DC_URL'] + "/validation"
    this.generateQrCode(this.appUrl);
  }

  /**
   * Side manager for stage Besuchernachweis
   *
   * <ul>
   *   <li>displays a QR code that needs to be scanned by the holder</li>
   *   <li>waits for response</li>
   *   <li>changes QR Code to image object</li>
   * </ul>
   * @private
   */
  requestBesuchernachweis() {
    if (this.htmlElementCheckBox!.style.display === 'none') {
      this.reset();
      return;
    }
    if (this.checkCounter < 2) {
      alert("Sie müssen mindestens 2 Stände besucht haben.");
      this.htmlElementCanvas!.style.display = 'none';
      return;
    }
    this.htmlElementCheckBox!.style.display = 'none';
    this.htmlElementCanvas!.style.display = 'block';

    if (this.checkDresden) { this.vcReqArray.push("Besuchernachweis.dresden"); }
    if (this.checkLeipzig) { this.vcReqArray.push("Besuchernachweis.leipzig"); }
    if (this.checkFFIT) { this.vcReqArray.push("Besuchernachweis.fit"); }
    if (this.checkKT) { this.vcReqArray.push("Besuchernachweis.kaprion"); }
    if (this.checkEECC) { this.vcReqArray.push("Besuchernachweis.eecc"); }
    if (this.checkHMW) { this.vcReqArray.push("Besuchernachweis.hmw"); }
    if (this.checkHTWD) { this.vcReqArray.push("Besuchernachweis.htwd"); }

    this.localDataStorageService.setData("credentialArray", this.vcReqArray); // credential to be requested
    this.genPPPMessage();
  }

  /**
   * Update Screen and prepare for next step
   *
   * @param credentials the received credential
   * @private
   */
  private processReceivedBesuchernachweisVC(credentials: VerifiableCredential1xModel[]) {
    let html = `<div id="stand">Validierung erfolgreich, Sie haben folgende Stände besucht:<br/><br/><ul>`;
    credentials.forEach(function (credential) {
      html += `<li>${(<BesuchernachweisModel>credential.credentialSubject).standName}</li>`
    })
    html += `</ul></div>`;
    this.htmlDomElementOfString(html).forEach(it => {
      document.getElementById('response')!.appendChild(it);
    });
    this.htmlElementCanvas!.style.display = 'none';
    this.htmlElementResponseText!.style.display = 'block';
  }

  /**
   * Converts a string with HTML syntax into DOM Elememnt
   * @param html the string to be converted
   * @private a list of ChildNodes
   */
  private htmlDomElementOfString(html: string): NodeListOf<ChildNode> {
    let template = document.createElement('template');
    html = html.trim();
    template.innerHTML = html;
    return template.content.childNodes;
  }

  /**
   * Generates QR code images from URL
   *
   * @param url a URL string
   * @private
   */
  private generateQrCode(url: string) {
    let canvas = document.getElementById("canvas");
    qrcode.toCanvas(canvas, url, function (error) {
      if (error) {
        alert("QR-Code konnte nicht erzeugt werden. Bitte versuchen Sie es erneut.");
      }
    })
  }

  checkCheckBoxvalue(event: any) {

    if (event.target.defaultValue === 'dresden' && !this.checkDresden) this.checkCounter--;
    else if (event.target.defaultValue === 'dresden' && this.checkDresden) this.checkCounter++;

    if (event.target.defaultValue === 'leipzig' && !this.checkLeipzig) this.checkCounter--;
    else if (event.target.defaultValue === 'leipzig' && this.checkLeipzig) this.checkCounter++;

    if (event.target.defaultValue === 'eecc' && !this.checkEECC) this.checkCounter--;
    else if (event.target.defaultValue === 'eecc' && this.checkEECC) this.checkCounter++;

    if (event.target.defaultValue === 'htwd' && !this.checkHTWD) this.checkCounter--;
    else if (event.target.defaultValue === 'htwd' && this.checkHTWD) this.checkCounter++;

    if (event.target.defaultValue === 'hmw' && !this.checkHMW) this.checkCounter--;
    else if (event.target.defaultValue === 'hmw' && this.checkHMW) this.checkCounter++;

    if (event.target.defaultValue === 'kaprion' && !this.checkKT) this.checkCounter--;
    else if (event.target.defaultValue === 'kaprion' && this.checkKT) this.checkCounter++;

    if (event.target.defaultValue === 'fit' && !this.checkFFIT) this.checkCounter--;
    else if (event.target.defaultValue === 'fit' && this.checkFFIT) this.checkCounter++;
  }

}
