<header class="ticketshop-heading">Ticketverkauf</header>
<div class="main-wrapper container-fluid">
  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div class="card border-0 card_shadow">
      <div class="card-body">

        <img [src]="imgURL + 'ticket-icon.png'" alt="Ticket Symbol" class="ticket-icon"/>

        <div class="flex-wrapper">
          <span class="ticketshop-button flex-item">Ticketauswahl</span>
          <span (click)="toggleTab(1)"
                [ngClass]="{'ticketshop-tab-button':true, 'ticketshop-tab-button-active': currentTab === 1}"
                class="flex-item">Favoriten</span>
          <span (click)="toggleTab(2)"
                [ngClass]="{'ticketshop-tab-button':true, 'ticketshop-tab-button-active': currentTab === 2}"
                class="flex-item">Zuletzt gekauft</span>
        </div>

        <div>
          <div *ngIf="currentTab === 1">
            <div *ngIf="isFavorites">
              <div *ngFor="let ticket of favoriteTickets">
                <div *ngIf="ticket.show" class="ticketshop-ticket-type">
                  <div class="ticketshop-ticket-info">
                    <span class="ticketshop-ticket-info-content">{{ticket.name}}</span>
                    <span class="ticketshop-ticket-info-content">{{ticket.zone}}</span>
                  </div>
                  <div (click)="removeFromFavoritesOrHistory(ticket)" class="ticketshop-ticket-delete">
                    <img [src]="imgURL + 'delete-icon.png'" class="ticketshop-delete-icon">
                  </div>
                  <div (click)="addToCart(ticket)" class="ticketshop-ticket-cart">
                    <img [src]="imgURL + 'cart-icon.png'" class="ticketshop-cart-icon">
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isFavorites">
              Keine bevorzugten Tickets verfügbar.
            </div>
          </div>
          <div *ngIf="currentTab === 2">
            <div *ngIf="isBoughtTickets">
              <div *ngFor="let ticket of boughtTickets">
                <div *ngIf="ticket.show" class="ticketshop-ticket-type">
                  <div class="ticketshop-ticket-info">
                    <span class="ticketshop-ticket-info-content">{{ticket.name}}</span>
                    <span class="ticketshop-ticket-info-content">{{ticket.zone}}</span>
                  </div>
                  <div (click)="removeFromFavoritesOrHistory(ticket)" class="ticketshop-ticket-delete">
                    <img [src]="imgURL + 'delete-icon.png'" class="ticketshop-delete-icon">
                  </div>
                  <div (click)="addToCart(ticket)" class="ticketshop-ticket-cart">
                    <img [src]="imgURL + 'cart-icon.png'" class="ticketshop-cart-icon">
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isBoughtTickets">
              Noch keine Tickets gekauft.
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="flex-wrapper">
    <ng-container *ngIf="true">
      <button type="button" (click)="onClickCancel()" class="link-box-white">Abbrechen</button>
      <button (click)="goToTicketSelection()" class="link-box-white" name="next">Tickets
      </button>
      <button (click)="showLoginForm()" [disabled]="true" class="link-box-white" name="show-login">Konto
      </button>
    </ng-container>
  </div>
</div>
<app-dialog-handler class="dialog-handler" *ngIf="blnShowDialogHandler && messageType!==undefined" [messageType]="messageType" (CancelAction)="cancelAction()" (OkAction)="okAction()" [headingMessageText]="alertMessage" [additionalMessageText]="hintMessage"></app-dialog-handler>

<!-- Footer -->
<!--<app-footer [footerButtons]="footerButtons" class="footer"></app-footer>-->
