import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { LocalDataStorageService } from './services/local-data-storage.service';
import { WebsocketService } from './services/websocket.service';
import { UseCaseModel } from './models/kaprion/use-case.model';
import { DataService } from './services/data.service';
import { AppConfigService } from 'src/app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'demo-app';
  imgURL = environment.imgURL;
  currentRouteHeader = 'KAPRION';
  currentHeaderMenuIcon = {
    'img': 'menu-icon.png',
    'icon_class': 'menu-icon',
    'menu_class': 'menu-popup-kaprion'
  };
  idiLogo = '';
  jhLogo = '';
  calleLogo = '';
  openid4vpLogo = '';
  teilAutoLogo = '';
  nextBikeLogo = '';

  isShowMenu: boolean = false;
  selectedMenuIndex: number = 0;
  isLinkToAssets: boolean = false;
  appInstanceOobid: string = "";
  environment: string = "";
  timestamp: string = "";
  hasSCC: boolean = true;

  /**
   * List of Uses Cases used for rendering the menu
   * @param id the index of this array
   * @param title the name of the menu entry/site mane
   * @param path the route path
   */
  useCases: UseCaseModel[] = [
    { id: 0, title: "Hauptseite", path: "/home" },
    //{ id: 1, title: "Kommunale Datenkarte (KDK)", path: "/credential" },
    { id: 1, title: "KommPass", path: "/kommpass" },
    { id: 2, title: "Wohngeldbescheid", path: "/wohngeldbescheid" },
    { id: 3, title: "Sozialpass", path: "/ddp" },
    { id: 4, title: "Eröffnung eines Bankkontos", path: "/bada" },
    { id: 5, title: "Bank-Login", path: "/badalogin" },
    { id: 6, title: "Erteilung des SEPA-Lastschriftmandats", path: "/sepamandate" },
    { id: 7, title: "Ticketverkauf", path: "/ticketshop" },
    { id: 8, title: "Mobilfunkvertrag", path: "/mobilecontract" },
    { id: 9, title: "Mobilfunkvertrag-Login", path: "/mobilecontractlogin" },
    { id: 10, title: "Nextbike-Vertrag", path: "/nextbike" },
    { id: 11, title: "Führerschein-Nachweis", path: "/drivinglicense" },
    { id: 12, title: "teilAuto-Vertrag", path: "/teilauto" },
    { id: 13, title: "Jungheinrich-Vertrag", path: "/jungheinrich" },
    //{ id: 14, title: "SCC2023 Besuchernachweis Ausstellung", path: "/party-selector" },
    //{ id: 15, title: "SCC2023 Besuchernachweis Validierung", path: "/sccv" },
    { id: 14, title: "OpenID4VP-Login", path: "/openid4vplogin" },
    { id: 15, title: "Privacy Policy", path: "/pp" },
    { id: 16, title: "Terms & Conditions", path: "/tc" }
  ];

  constructor(private router: Router,
    private websocketService: WebsocketService,
    private localDataStorageService: LocalDataStorageService,
    private dataService: DataService,
    private appConfigService: AppConfigService) {
    this.router.events.subscribe((route: any) => {
      if (route['url'] !== undefined) {
        let filteredIndexes = this.useCases.filter((x: any) => x['path'] === route['url']);
        if (filteredIndexes.length > 0) {
          this.selectedMenuIndex = filteredIndexes[0]['id'];
        }
        this.localDataStorageService.setData("oobid", "");
        if (route['url'] === '/credential' || route['url'] === '/wohngeldbescheid' || route['url'] === '/ddp' || route['url'] === '/drivinglicense' || route['url'] === '/kommpass') {
          this.currentRouteHeader = 'DRESDEN';
          this.currentHeaderMenuIcon = {
            'img': 'menu_icon_white.png',
            'icon_class': 'menu-icon-white',
            'menu_class': 'menu-popup-dresden'
          };
        } else if (route['url'] === '/ticketshop' || route['url'].indexOf('/ticketselection/') > -1) {
          this.currentRouteHeader = 'DVB';
          this.currentHeaderMenuIcon = {
            'img': 'menu-icon.png',
            'icon_class': 'menu-icon',
            'menu_class': 'menu-popup-dvb'
          };
        } else if (route['url'] === '/sepamandate' || route['url'] === '/bada' || route['url'] === '/badalogin') {
          this.currentRouteHeader = 'IDI';
          this.currentHeaderMenuIcon = {
            'img': 'menu_icon_white.png',
            'icon_class': 'menu-icon-white',
            'menu_class': 'menu-popup-idi'
          };
          this.getOutputDescriptors("SEPADirectDebitMandate").then((logo) => {
            this.idiLogo = logo;
          });
        } else if (route['url'] === '/mobilecontract' || route['url'] === '/mobilecontractlogin') {
          this.currentRouteHeader = 'CALLE';
          this.currentHeaderMenuIcon = {
            'img': 'menu_icon_white.png',
            'icon_class': 'menu-icon-white',
            'menu_class': 'menu-popup-calle'
          };
          this.getOutputDescriptors("MobilePhoneContract").then((logo) => {
            this.calleLogo = logo;
          });
        } else if (route['url'] === '/jungheinrich') {
          this.currentRouteHeader = 'JH';
          this.currentHeaderMenuIcon = {
            'img': 'menu_icon_white.png',
            'icon_class': 'menu-icon-white',
            'menu_class': 'menu-popup-black'
          };
          this.getOutputDescriptors("Jungheinrich").then((logo) => {
            this.jhLogo = logo;
          });
        } else if (route['url'] === '/teilauto') {
          this.currentRouteHeader = 'teil';
          this.currentHeaderMenuIcon = {
            'img': 'menu_icon_white.png',
            'icon_class': 'menu-icon-white',
            'menu_class': 'menu-popup-teil'
          };
          this.getOutputDescriptors("teilAuto").then((logo) => {
            this.teilAutoLogo = logo;
          });
        } else if (route['url'] === '/nextbike') {
          this.currentRouteHeader = 'next';
          this.currentHeaderMenuIcon = {
            'img': 'menu_icon_white.png',
            'icon_class': 'menu-icon-white',
            'menu_class': 'menu-popup-next'
          };
          this.getOutputDescriptors("Nextbike").then((logo) => {
            this.nextBikeLogo = logo;
          });
        } else if (route['url'] === '/openid4vplogin') {
          this.currentRouteHeader = 'openid4vplogin';
          this.currentHeaderMenuIcon = {
            'img': 'menu-icon.png',
            'icon_class': 'menu-icon',
            'menu_class': 'menu-popup-openid4vplogin'
          };
        } else {
          this.currentRouteHeader = 'KAPRION';
          this.currentHeaderMenuIcon = {
            'img': 'menu-icon.png',
            'icon_class': 'menu-icon',
            'menu_class': 'menu-popup-kaprion'
          };
        }
      }
    });
  }

  ngOnInit() {
    this.appInstanceOobid = crypto.randomUUID();
    this.localDataStorageService.setData("sessionId", this.appInstanceOobid);
    this.timestamp = this.appConfigService.getConfig().TIMESTAMP;
    this.localDataStorageService.setData("additionalFavorite", -1)
  }

  // to show the menu
  showMenu() {
    this.isShowMenu = true;
  }

  // to hide the menu
  hideMenu() {
    this.isShowMenu = false;
  }

  // to navigate on menu selection
  menuSelect(index: number) {
    this.selectedMenuIndex = index;

    if (index < this.useCases.length) {
      this.router.navigate([this.useCases[index].path]);
    }
    else {
      this.router.navigate([this.useCases[0].path]);
    }

    this.isShowMenu = false;
  }

  onSubmitCredentials() {
    this.router.navigate(['/qrcode/']);
  }

  /**
   * to get the output descriptor and fetch logo from it
   * @param outputdescriptorFragment 
   * @returns 
   */
  getOutputDescriptors(outputdescriptorFragment: string): Promise<any> {
    return new Promise<Object>((resolve, reject) => {
      let outputDescUrl = window.origin + "/assets/outputdescriptors/v1/" + outputdescriptorFragment + '.json';
      this.dataService.retrieveFile(outputDescUrl).then((outputdescriptor: any) => {
        if (outputdescriptor !== null && outputdescriptor !== undefined && outputdescriptor !== ""
          && outputdescriptor["styles"] !== null && outputdescriptor["styles"] !== undefined && outputdescriptor["styles"] !== ""
          && outputdescriptor["styles"]["thumbnail"] !== null && outputdescriptor["styles"]["thumbnail"] !== undefined && outputdescriptor["styles"]["thumbnail"] !== ""
          && outputdescriptor["styles"]["thumbnail"]["uri"] !== null && outputdescriptor["styles"]["thumbnail"]["uri"] !== undefined && outputdescriptor["styles"]["thumbnail"]["uri"] !== ""
        ) {
          resolve(outputdescriptor["styles"]["thumbnail"]["uri"]);
          return outputdescriptor["styles"]["thumbnail"]["uri"];
        } else {
          resolve("");
          return "";
        }
      });
    });
  }
}
