// component shows home page with favorites and history of tickets
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';
import { Ticket, ticketPreSet } from 'src/app/models/vvo/VVO';
import { MessageType } from 'src/app/components/ui/dialog-handler/dialog-handler.component';

@Component({
  selector: 'app-ticketshop',
  templateUrl: './ticketshop.component.html',
  styleUrls: ['./ticketshop.component.scss']
})
export class TicketshopComponent implements OnInit {

  @Input() sessionId: string = "";

  alertMessage: string = "";
  hintMessage: string = "";
  messageType: MessageType | undefined = undefined;
  blnShowDialogHandler: boolean = false;

  imgURL = environment.imgURL;
  currentTab = 1;
  isFavorites = true;

  // list favorite tickets
  favoriteTickets = [
    {
      name: "Deutschlandticket",
      zone: "Preisstufe E, Deutschlandweit",
      value: 14,
      show: true
    },
    {
      name: "Einzelfahrt",
      zone: "Preisstufe A1, Dresden",
      value: 1,
      show: true
    },
    {
      name: "Tageskarte",
      zone: "Preisstufe A1, Dresden",
      value: 0,
      show: true
    }
  ];
  isBoughtTickets = true;

  // list of ticket history
  boughtTickets = [
    {
      name: "Tageskarte",
      zone: "Preisstufe A1, Dresden",
      value: 3,
      show: true
    }
  ];

  // list of footer buttons
  footerButtons = [
    "Zurück",
    "Tickets",
    "Konto"
  ];

  constructor(private router: Router,
    private localDataStorageService: LocalDataStorageService) { }

  ngOnInit(): void {
    this.addToFavorite();
  }

  /**
   * to handle the tab change
   * @param id
   */
  toggleTab(id: number) {
    this.currentTab = id;
  }

  /**
   * to add tickets to the cart
   * @param ticket
   */
  addToCart(ticket: any) {
    this.router.navigate(['/ticketselection', ticket.value]);
  }

  /**
   * to remove tickets from favorite or history lists
   * @param ticket
   */
  removeFromFavoritesOrHistory(ticket: any) {
    ticket['show'] = false;
    let visibleFavorites = this.favoriteTickets.filter(ticket => ticket.show);
    let visibleBoughtTickets = this.boughtTickets.filter(ticket => ticket.show);
    if (visibleFavorites.length === 0) {
      this.isFavorites = false;
    }
    if (visibleBoughtTickets.length === 0) {
      this.isBoughtTickets = false;
    }
  }

  addToFavorite() {
    let newFavorite = this.localDataStorageService.getData("additionalFavorite");
    if (newFavorite > -1) {
      let ticket: Ticket | undefined = ticketPreSet.find(t => t.id === newFavorite);
      if (ticket !== undefined) {
        let favorite = {
          name: ticket.price.name,
          zone: this.getZoneAndPriceLevel(ticket),
          value: newFavorite,
          show: true
        }
        if (this.favoriteTickets.find(fav => fav.value === newFavorite) === undefined) {
          this.favoriteTickets.push(favorite);
        }
      }
    }
  }

  getZoneAndPriceLevel(ticket: Ticket): string {
    let zoneAndPriceLevel: string = "Preisstufe " + ticket.priceLevel + ", "
    ticket.zone.forEach(zone => {
      zoneAndPriceLevel += zone + " "
    });
    return zoneAndPriceLevel;
  }

  onClickCancel() {
    this.messageType = MessageType.OkCancelDialog;
    this.alertMessage = "Möchten Sie zur Credentialauswahl zurückkehren?";
    this.hintMessage = "Ihre Eingaben gehen dabei verloren.";
    this.blnShowDialogHandler = true;
  }
  
    /**
   * catches emitter from dialog-handler
   */
  cancelAction() {
    this.blnShowDialogHandler = false;
  }

  /**
 * catches emitter from dialog-handler
 */
  okAction() {
    this.blnShowDialogHandler = false;
  }

  showLoginForm() {
    alert("Die Kontoverwaltung steht in dieser Demo nicht zur Verfügung.")
  }

  goToTicketSelection() {
    this.router.navigate(["ticketselection/" + 1])
  }


}
