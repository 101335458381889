<header class="heading">Sozialpass am Beispiel der Landeshauptstadt Dresden (Minimalsetup)</header>

<div class="main-wrapper container-fluid">
  <!-- BEGIN aside box -->
  <div *ngIf="!showQrCode" class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div class="card border-0 card_shadow">
      <div class="card-body">
        <p>
          Anwendungsfall Sozialpass (z.B. der Stadt Dresden)
        <ol>
          <li>Abfrage des KDK Bildnachweises</li>
          <li>Abfrage des Wohngeldbescheids.</li>
          <li>Herausgabe des Sozialpasses</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- END aside box -->

  <app-presentation *ngIf="proofArray.length === 2" [proofArray]="proofArray"></app-presentation>
  <!-- BEGIN button wrapper -->
  <div style="display: block;" class="vh-73">
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="showQrCode">
      <div class="card border-0 card_shadow">
        <div id="content-box">
          <app-showqrcode *ngIf="showQrCode" class="qr-code" [verificationUrl]="appUrl"
            [isRequestPresentation]="isRequestPresentation" [isDresdenPass]="false"></app-showqrcode>
        </div>
      </div>
    </div>
    <div [ngClass]="{'flex-wrapper': true, 'qrcode-flex-wrapper': showQrCode && proofArray.length < 2}" style="margin: auto 0;">
      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" style="margin: auto 0;">
        <button *ngIf="!showQrCode" type="button" (click)="onClickCancel()" class="link-box-white">Abbrechen</button>
        <button *ngIf="(proofArray.length === 0 || proofArray.length === 2) && !showQrCode" type="submit"
          (click)="onSubmit()" class="link-box-white">{{buttonText}}</button>
      </div>
    </div>
  </div>
  <!-- END button wrapper -->
  <app-dialog-handler class="dialog-handler" *ngIf="blnShowDialogHandler && messageType!==undefined" [messageType]="messageType" (CancelAction)="cancelAction()" (OkAction)="okAction()" [headingMessageText]="alertMessage" [additionalMessageText]="hintMessage"></app-dialog-handler>
  <ng-container *ngFor="let message of messageArray">
    <app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
  </ng-container>