import { Injectable } from '@angular/core';
import { LocalDataStorageData } from './local-data-storage-data.interface';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable({
  providedIn: 'root'
})
export class LocalDataStorageService {

  private data: LocalDataStorageData = {
    sessionId: "",
    ticketIdArray: [],
    credentialArray: [],
    taskType: "",
    oobid: "",
    currentParty: "",
    navigationalFragment: "",
    additionalFavorite: -1,
    requestPresentation: [],
    loginInformation: {},
    purposeObjectArray: []
  }

  private data$ = new ReplaySubject<LocalDataStorageData>();

  constructor() { }

  /**
   * To set cache properties
   */
  setData<T extends keyof LocalDataStorageData, K extends LocalDataStorageData[T]>(strDataKey: T, strDataValue: K) {
    this.data[strDataKey] = strDataValue;
    this.data$.next({ ...this.data });
  }

  /**
   * To get cached properties
   */
  getData<T extends keyof LocalDataStorageData>(strDataKey: T) {
    return this.data[strDataKey];
  }
/**
 * reset all globals all at once
 */
  resetAllGlobals() {
    this.setData("sessionId", "")
    this.setData("ticketIdArray", [])
    this.setData("credentialArray", [])
    this.setData("taskType", "")
    this.setData("oobid", "")
    this.setData("currentParty", "")
    this.setData("navigationalFragment", "")
    this.setData("additionalFavorite", -1)
    this.setData("requestPresentation", [])
    this.setData("loginInformation", {})
    this.setData("purposeObjectArray", [])
  }
}
